import React, {useState} from 'react';
import AuthForm from "./forms/AuthForm";
import TokenForm from "./forms/TokenForm";
import CommandButton from "./forms/CommandButton";
import WT from "@sscale/wtsdk";
import {authRequest} from "./API/TokenService";
import {useStorage} from "../hooks/useStorage";
import CustomInput from "./UI/input/customInput";
import CustomButton from "./UI/button/customButton";

const UserInputs = () => {

    const defaultConnectionParams = {
        authUrl: "https://auth.s1.sceenic.co/token/v2/",
        authToken: "rqouSTUwTrmNVCQK8Lt/lw==",
        sessionId: 'sceenic',
        streamingToken: "",
        participantName: ""
    }

    const [connectionParams, setConnectionParams] = useState({...defaultConnectionParams})
    useStorage(setConnectionParams);

    const onConnect = async (event) => {
        event.preventDefault();
        if (connectionParams.streamingToken) {
            console.log("User set streaming token", connectionParams.streamingToken)
            WT.Session.connect(connectionParams.streamingToken, connectionParams.participantName);
        } else {
            const {streaming_token} = await authRequest(
                connectionParams.authUrl,
                connectionParams.authToken,
                connectionParams.sessionId
            );
            console.log("xAuth token", streaming_token)
            streaming_token
                ? WT.Session.connect(streaming_token, connectionParams.participantName)
                : alert("Unable to get token from xAuth!")
        }
    }

    const onDisconnect = (event) => {
        event.preventDefault();
        WT.Session.disconnect();
    }

    const onChangeParticipantName = (event) => {
        setConnectionParams({...connectionParams, participantName: event.target.value})
    }

    const onClickDefault = (e) => {
        localStorage.clear()
        setConnectionParams(defaultConnectionParams);
    }

    return (
        <div>
            <TokenForm setConnectionParams={setConnectionParams} connectionParams={connectionParams}/>
            <AuthForm setConnectionParams={setConnectionParams} connectionParams={connectionParams}/>
            <CustomInput
                id="participant"
                type="text"
                placeholder="Participant name"
                value={connectionParams.participantName}
                onChange={onChangeParticipantName}
                withLabel
            />
            <div className="buttons">
                <CommandButton
                    onConnect={onConnect}
                    onDisconnect={onDisconnect}/>
                <CustomButton onClick={onClickDefault}>
                    To default
                </CustomButton>
            </div>
        </div>
    );
};

export default UserInputs;