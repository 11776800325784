import React from 'react';
import classes from './Input.module.css'

const CustomInput = ({withLabel=false, ...props}) => {
    return (
        <div className={classes.inputForm}>
            {withLabel && <label className={classes.customLabel} htmlFor={props.id}>{props.placeholder}</label>}
            <input className={classes.customInput} {...props}/>
        </div>
    );
};

export default CustomInput;