import React, {useState} from 'react';
import WT from "@sscale/wtsdk";
import CustomButton from "./UI/button/customButton";
import classes from "./UI/button/Button.module.css";

const VideoControls = () => {
    const [enableAudio, setEnableAudio] = useState(true);
    const [enableVideo, setEnableVideo] = useState(true);

    const onAudioClick = (event) => {
        event.preventDefault();
        const audioStatus = WT.Participant.isAudioEnabled();
        audioStatus ?
            WT.Participant.disableAudio() :
            WT.Participant.enableAudio();
        setEnableAudio(!audioStatus);
    }

    const onVideoClick = (event) => {
        event.preventDefault();
        const videoStatus = WT.Participant.isVideoEnabled();
        videoStatus ?
            WT.Participant.disableVideo() :
            WT.Participant.enableVideo()
        setEnableVideo(!videoStatus);
    }


    return (
        <div>
            <CustomButton className={enableVideo ? classes.enableButton : classes.disableButton}
                          onClick={onVideoClick}>Video</CustomButton>
            <CustomButton className={enableAudio ? classes.enableButton : classes.disableButton}
                          onClick={onAudioClick}>Audio</CustomButton>
        </div>
    );
};

export default VideoControls;