import React from 'react';
import CustomButton from "../UI/button/customButton";

const CommandButton = ({onConnect, onDisconnect}) => {
    return (
        <div className="command-commands">
            <CustomButton
                // disabled={connected}
                onClick={onConnect}>
                Connect
            </CustomButton>
            <CustomButton
                // disabled={!connected}
                onClick={onDisconnect}>
                Disconnect
            </CustomButton>
        </div>
    );
};

export default CommandButton;