import React from 'react';
import CustomInput from "../UI/input/customInput";

const AuthForm = ({ connectionParams, setConnectionParams }) => {

    const onChange = (field) => (event) => {
        setConnectionParams(prevConnectionParam => ({...prevConnectionParam, [field]: event.target.value}))
        localStorage.setItem(field, JSON.stringify(event.target.value))
    }

    return (
        <div className="user-inputs">
            <CustomInput
                id="authURL"
                type="text"
                placeholder="AuthURL"
                value={connectionParams.authUrl}
                onChange={onChange("authUrl")}
                required
                withLabel
            />
            <CustomInput
                id="authToken"
                type="text"
                placeholder="Token"
                value={connectionParams.authToken}
                onChange={onChange("authToken")}
                required
                withLabel
            />
            <CustomInput
                id="room"
                type="text"
                placeholder="Room"
                value={connectionParams.sessionId}
                onChange={onChange("sessionId")}
                required
                withLabel
            />
        </div>
    );
};

export default AuthForm;