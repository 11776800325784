import React from "react";
import Session from "./components/Session";
import './styles/App.css'

function App() {

    return (

        <div className="App">
            <Session className='Session'/>
        </div>);
}

export default App;