import React from 'react';
import CustomInput from "../UI/input/customInput";

const TokenForm = ({setConnectionParams, connectionParams}) => {

    const onChange = (event) => {
        setConnectionParams(prevConnectionParam => (
            {...prevConnectionParam, streamingToken: event.target.value}
        ))
        localStorage.setItem("streamingToken", JSON.stringify(event.target.value))
    }

    return (
        <div className="user-inputs">
            <CustomInput
                id="streamingToken"
                type="text"
                placeholder="Streaming token"
                value={connectionParams.streamingToken}
                onChange={event => onChange(event)}
                required
                withLabel
            />
        </div>
    );
};

export default TokenForm;