import React from 'react';
import UserInputs from "./UserInputs";
import VideoGrid from "./VideoGrid";
import VideoControls from "./VideoControls";
import {useListeners} from "../hooks/useListeners";

const Session = () => {

    const participants = useListeners();


    return (
        <div>
            <UserInputs/>
            {participants.length ? <VideoControls/> : null}
            <VideoGrid participants={participants}/>
        </div>
    );
};

export default Session;